.avatarElement {
	background-color: #5b7fb4;
	/* width: 100px; */
	/* height: 100px; */
	/* font-size: 2rem; */
	color: #FFF;
	text-align: center;
	/* line-height: 100px; */
	border-radius: 90px;
    text-shadow: 2px 2px 4px black;
}
