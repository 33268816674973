.header {
	position: fixed;
	z-index: 900;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 5rem;
	align-items: stretch;
	display: flex;
	justify-content: space-evenly;
	background-color: rgb(221, 229, 243);
	padding: 0 0rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.containerLogo {
	width: 20%;
}

.logo {
	max-width: 250px;
	max-height: 5rem;
	padding: 1rem;
}

.containerAlert {
	z-index: 1000;
	max-width: 60%;
	margin: auto;
	display: flex;
	align-items: center;
}

.containerLogin {
	width: 25%;
	display: flex;
	padding-right: 1rem;
	justify-content: right;
	align-items: center;
}
