.statement {
	text-align: center;
	font-weight: bold;
	font-size: 2rem;
}

.group {
	display: block;
	justify-content: left;
	height: auto;
}

.buttonContainer {
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
}

.button {
	margin: 1rem;
	font-weight: bold;
	font-size: 1rem;
	border-radius: 8px;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	min-width: 10rem;
	height: 2rem;
	color: black;
}

.button:hover,
.button:active {
	cursor: pointer;
}

.submit {
	background-color: #ddf3e0;
}

.submit:hover {
	background-color: #b7e6be;
}

.invalid {
	background-color: #e0ddf3;
	color: lightgray;
}

.invalid:hover {
	/* background-color: #beb7e6; */
	cursor: default;
}

.failure {
	background-color: #f5e7e7;
	border: 1px solid #b40e0e;
	border-radius: 8px;
	text-align: center;
	font-weight: bold;
	padding: 0.5rem;
}